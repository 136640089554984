@import "constants/style";

.exit-button {
	position: absolute;
	top: -95px;
	right: 25px;

	img {
		margin-right: 0;
		display: none;
	}

	@media (max-width: $padMaxWidth) {
		padding: 0 10px;

		span {
			display: none;
		}

		img {
			margin-right: 0 !important;
			display: inline-block;
		}
	}

	@media (max-width: $mobileMaxWidth) {
		top: -69px;
		right: 10px;
		padding: 0 10px;
	}
}

.profile-form {
	& > div {
		display: flex;
		align-items: flex-start;
		margin: 10px 30px;

		& > span {
			min-width: 210px;
			max-width: 100%;
			line-height: 48px;
			margin-right: 15px;
			white-space: nowrap;
		}

		& > .profile-input {
			flex-grow: 1;
		}

		&.buttons {
			justify-content: flex-end;
			margin-top: 20px;

			& > * {
				margin: 5px;
			}
		}

		@media (max-width: $padMaxWidth) {
			flex-direction: column;
			align-items: stretch;
			margin: 10px 10px;

			& > span {
				min-width: 0;
				line-height: normal;
				white-space: normal;
			}
		}
	}
}
