@import "constants/style";

.test-tabs {
	display: flex;
	margin-bottom: 10px;

	.test-tabs-tab {
		flex-grow: 1;
		padding: 0;

		&:first-child {
			margin-right: 10px;
		}
	}
}

.tests-loading {
	display: flex;
	padding: 50px 0;
	justify-content: center;
}

.test-form {
	display: block !important;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	.tests-members-list {
		margin-bottom: 10px;
	}
}

.search-test {
	margin-bottom: 20px;
}

.tests-list {
	margin-right: -30px;
	margin-left: -30px;
	margin-bottom: -30px;
	height: 575px;
	overflow: auto;

	@media (max-width: $displayMaxWidth) {
		margin-right: -15px;
		margin-left: -15px;
		margin-bottom: -15px;
	}

	@media (max-width: $mobileMaxWidth) {
		height: 405px;
	}

	& > div {
		font-size: 16px;
		color: #696871;
		padding: 22px 30px;
		transition: 0.2s;
		cursor: pointer;

		@media (max-width: $displayMaxWidth) {
			padding-right: 15px;
			padding-left: 15px;
		}

		@media (max-width: $mobileMaxWidth) {
			font-size: 14px;
			line-height: 17px;
			padding: 14px 15px;
		}

		&:not(.selected):hover {
			background: #f6f6fc;
		}

		&.selected {
			background: #ededf6;
			color: #352e6a;
			font-weight: 500;
		}


		&:not(.history) {
			line-height: 20px;
		}

		&.history {
			display: flex;
			flex-direction: column;
			padding: 14px 30px;

			& > div {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: flex-start;

				& > span:nth-child(2) {
					font-size: 0.7em;
					opacity: 0.6;
				}
			}

			& > span {
				margin-top: 5px;
				text-align: right;
				font-size: 0.8em;
			}
		}
	}
}

.test-history-results-test-name {
	font-size: 1.2em !important;
}

.test-history-results {
	list-style: none;
	margin: 0;
	padding: 0;
	margin-bottom: 10px;

	li {
		margin: 20px 0;

		div:first-child {
			margin-bottom: 5px;
			padding-right: 20px;
			font-size: 1em;
		}

		div:last-child {
			padding-left: 20px;
			text-align: right;
			font-size: 0.8em;
			font-weight: bold;
			color: $color;
		}
	}
}

.test-interpretation-container {
	display: flex;
	flex-direction: column;
	padding: 0 !important;

	.test-interpretation-text {
		padding: 20px;
		overflow: auto;
	}

	.close-interpretation {
		margin: 20px 0;
		text-align: center;
	}
}
