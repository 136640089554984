@import "constants/style";

.app-spinner {
	animation: app-spinner-rotate 2s linear infinite;

	& > circle {
		stroke: $color;
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	&.invert > circle {
		stroke: $back;
	}

}

@keyframes app-spinner-rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 150;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -124;
	}
}
