.info-block {
	padding: 30px;
	background: -webkit-gradient(linear, right top, left top, from(rgba(48, 43, 99, .2)), color-stop(48.9%, #302b63));
	background: linear-gradient(270deg, rgba(48, 43, 99, .2) 0, #302b63 48.9%);
	border-radius: 10px;
	-ms-grid-column: 3;
	grid-column: 3;
	-ms-grid-row: 1;
	grid-row: 1;

	.title {
		font-family: Hero, sans-serif;
		font-weight: 700;
		font-size: 26px;
		line-height: 31px;
		color: #fff;
	}

	.desc {
		font-size: 14px;
		line-height: 21px;
		color: rgba(255, 255, 255, .8);
		margin-top: 7px;
	}

	@media (max-width:767px) {
		-ms-grid-column: span 3;
		grid-column: span 3;
		-ms-grid-column-span: 3;
		-ms-grid-row: 1;
		grid-row: 1;
		padding: 20px;

		.title {
			font-size: 18px;
			line-height: 22px;
		}

		.desc {
			font-size: 12px;
			line-height: 15px;
			margin-top: 5px;
		}
	}
}
