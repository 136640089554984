.right-info {
	display: flex;
	align-items: center;

	.notif-block {
		position: relative;
		margin-right: 13px;
		cursor: pointer;

		.new {
			width: 10px;
			height: 10px;
			background: #ffc200;
			border: 1px solid #f6f5fa;
			position: absolute;
			top: 3px;
			right: -1px;
			border-radius: 50%
		}

		.icon {
			width: 26px;
			height: 32px;
			background: url('/assets/img/icon__bell.png') no-repeat;
			background-size: contain
		}

		&.open .notif-block__inner {
			opacity: 1;
			pointer-events: auto;
			-webkit-transform: scale(1) translateY(0);
			-ms-transform: scale(1) translateY(0);
			transform: scale(1) translateY(0)
		}

		.notif-block__inner {
			position: absolute;
			background: #fff;
			box-shadow: 0 0 5px rgba(0, 0, 0, .0986943);
			border-radius: 10px;
			width: 305px;
			right: -62px;
			margin-top: 20px;
			padding: 20px;
			cursor: default;
			-webkit-transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
			transition: all .2s cubic-bezier(.5, 0, 0, 1.25), opacity .15s ease-out;
			z-index: 9;
			-webkit-transform: scale(.75) translateY(-20%);
			-ms-transform: scale(.75) translateY(-20%);
			transform: scale(.75) translateY(-20%);
			-webkit-transform-origin: 50% 0;
			-ms-transform-origin: 50% 0;
			transform-origin: 50% 0;
			pointer-events: none;
			opacity: 0;

			&:before {
				content: "";
				display: block;
				border: 15px solid transparent;
				border-bottom: 15px solid #fff;
				position: absolute;
				top: -20px;
				right: 60px
			}

			.top {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
				margin-bottom: 15px;

				.name {
					font-weight: 600;
					font-size: 16px;
					line-height: 150%;
					color: #19191b
				}

				.see-all {
					font-weight: 600;
					font-size: 10px;
					line-height: 12px;
					text-align: right;
					text-decoration: underline;
					color: #504da6
				}
			}

			.notif-list .item {
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				-webkit-box-pack: justify;
				-webkit-justify-content: space-between;
				-ms-flex-pack: justify;
				justify-content: space-between;
				font-size: 10px;
				line-height: 12px;
				color: #35384d;

				&:not(:last-child) {
					margin-bottom: 10px
				}

				.name {
					font-weight: 500;
					font-size: 10px
				}
			}
		}
	}

	.name {
		display: flex;
		flex-direction: row;
		align-items: center;
		font-size: 14px;
		line-height: 17px;
		color: #19191b;

		.photo {
			background: #696871;
			width: 37px;
			height: 37px;
			border-radius: 50%;
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			background-size: cover;
			background-position: center;
		}
	}

	@media (max-width: 767px) {
		.name {
			font-size: 0;

			.photo {
				margin-right: 0;
			}
		}
	}

}
