@import "constants/style";

$fontSize: 16px;
$height: 40px;

.app-button {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: $height;
	padding: 0 36px;
	border: 0;
	border-radius: 5px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: $color;
	color: $back;
	transition: opacity 0.1s, background 0.1s;

	&:hover:not(.invert):not(:disabled) {
		background: $activeColor;
	}

	&:active:not(:disabled) {
		opacity: 0.5;
	}

	&.disabled {
		background: $disabledColor;
		cursor: default;
	}

	&.invert {
		border: 1px solid $color;
		background: $back;
		color: $color;

		.spinner div {
			border-color: $color;
		}

		&.disabled {
			border-color: $disabledColor;
			color: $disabledColor;

			.spinner div {
				border-color: $disabledColor;
			}
		}
	}

	.transparent {
		opacity: 0;
	}

	&:not(.loading):not(:disabled) {
		cursor: pointer;
	}

	&:not(.loading) .spinner {
		display: none;
	}

	&.button-icon.button-title img {
		margin-right: 5px;
	}

	span {
		font-family: Montserrat,sans-serif;
		font-size: $fontSize;
	}

	img {
		max-height: 24px;
	}

	.spinner {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&.disabled > .spinner {
		opacity: 0.7;
	}
}
