@import "constants/style";

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&::-ms-input-placeholder     {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&::placeholder      		 {@content}
}

.app-input-container {
	display: inline-block;
	width: 100%;

	&>input {
		width: 100%;
		background: $back no-repeat center;
		border: 1px solid $border;
		border-radius: 10px;
		height: 48px;
		padding: 12px;
		font-family: Montserrat,sans-serif;
		font-size: 16px;
		color: $controlText;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;

		@include placeholder {
			color: $placeholder;
		}

		&:focus {
			border-color: $color;
		}

		&:disabled {
			border-color: $disabledBorder;
			color: $disabledControlText;
		}

		&.icon {
			background-size: 24px;
			background-position-x: 12px;
			padding-left: 48px;
		}

		@media (max-width: $mobileMaxWidth) {
			height: 40px;
			font-size: 12px;

			&.icon {
				background-size: 20px;
				padding-left: 44px;
			}
		}
	}

	&>div {
		display: none;
	}

	&.error>input {
		border-color: $error;
	}

	&.error>div {
		display: block;
		color: $error;
		font-size: 0.7em;
		margin-top: 1px;
		margin-left: 10px;
	}
}
