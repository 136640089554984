@import "constants/style";

#initing {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.top-right-block {
	display: flex;
	flex-direction: row;

	& > *:not(last-child) {
		margin-right: 10px;
	}

	.langs {
		padding-right: 30px;
	}
}

.btn-block {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	& > * {
		padding: 0 !important;
		flex-grow: 1;

		&:not(:first-child) {
			@media (min-width: $mobileMaxWidth) {
				margin-left: 10px !important;
			}
			@media (max-width: $mobileMaxWidth) {
				margin-top: 10px !important;
			}
		}
	}

	@media (max-width: $mobileMaxWidth) {
		flex-direction: column;
		align-items: stretch;
	}
}

.main .sidebar {
	.menu-block {
		padding: 0;
		margin: 0;
		margin-right: -28px;

		@media (max-width: $mobileMaxWidth) {
			margin-right: -5px;
		}

		li {
			list-style-type: none;
			display: block;
			margin-top: -9px;

			@media (max-width: $mobileMaxWidth) {
				margin-top: 0;
			}

			.link {
				height: 95px;
				display: -webkit-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-webkit-align-items: center;
				-ms-flex-align: center;
				align-items: center;
				padding-left: 26px;

				@media (max-width: $mobileMaxWidth) {
					padding-left: 0;
					-webkit-box-pack: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: center;
					height: 65px;
				}

				&.disabled {
					opacity: 0.3;
					cursor: default;
				}

				.icon {
					width: 27px;
					height: 30px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					margin-right: 24px;

					@media (max-width: $mobileMaxWidth) {
						margin-right: 0;
						margin-left: -5px;
					}

					&.icon1 {
						background-image: url('/assets/img/icon__menu1.png');
					}

					&.icon2 {
						background-image: url('/assets/img/icon__menu2.png');
					}

					&.icon3 {
						background-image: url('/assets/img/icon__menu3.png');
					}

					&.icon4 {
						background-image: url('/assets/img/icon__menu4.png');
					}
				}

				.text {
					font-size: 18px;
					line-height: 22px;
					color: #fff;

					@media (max-width: $mobileMaxWidth) {
						font-size: 0;
					}
				}

				&.active {
					background: url('/assets/img/bg__menu-active.png') no-repeat;
					background-position: right center;

					@media (max-width: $mobileMaxWidth) {
						background-image: url('/assets/img/bg__menu-active__mobile.png');
					}

					.icon {
						&.icon1 {
							background-image: url('/assets/img/icon__menu1-hover.png');
						}

						&.icon2 {
							background-image: url('/assets/img/icon__menu2-hover.png');
						}

						&.icon3 {
							background-image: url('/assets/img/icon__menu3-hover.png');
						}

						&.icon4 {
							background-image: url('/assets/img/icon__menu4-hover.png');
						}
					}

					.text {
						color: $color;
						font-weight: 500;
					}
				}
			}
		}
	}
}

@media not print {
	.print {
		display: none;
	}
}

@media print {
	body, .main-container {
		background: none !important;
	}
	.main {
		grid-template-columns: 0 0 1fr;
	}
	.sidebar, .top-block, footer.main-footer {
		display: none;
	}
	.stat-block {
		page-break-before: auto;
		page-break-inside: avoid;
	}
}
