.welcome-text {
	margin: 0 20px;
	line-height: 30px;
	font-size: 20px;
	margin-bottom: 20px;
}

.welcome-button {
	float: right;
	margin-right: 20px;
	height: auto;
	padding: 10px 20px;
}
