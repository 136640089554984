@import "constants/style";

@mixin placeholder {
	&::-webkit-input-placeholder {@content}
	&::-ms-input-placeholder     {@content}
	&:-moz-placeholder           {@content}
	&::-moz-placeholder          {@content}
	&::placeholder      		 {@content}
}

.app-calendar-container {
	display: inline-flex;
	align-items: center;
	position: relative;
	width: 100%;
	background: $back url('/assets/img/icon__calendar.png') no-repeat 15px center;
	border: 1px solid $border;
	border-radius: 10px;
	height: 48px;
	padding: 12px;
	padding-left: 50px;
	font-family: Montserrat, sans-serif;
	font-size: 16px;
	color: $controlText;
	transition: all .2s ease;
	cursor: pointer;

	&.disabled {
		cursor: default;
		border-color: $disabledBorder;
		color: $disabledControlText;
	}

	span {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&.placeholder {
			color: $placeholder;
		}
	}

	@media (max-width: $mobileMaxWidth) {
		height: 40px;
		font-size: 12px;
	}

	.calendar-popup {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 100%;
		left: 5px;
		width: 350px;
		height: 500px;
		border-radius: 6px;
		box-shadow: 0 0 5px rgb(0 0 0 / 30%);
		overflow: hidden;
		background-color: #fff;
		z-index: 99999;

		@media (max-width: $mobileMaxWidth) {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 9999;
			border-radius: 0;
		}

		.type-selector {
			display: flex;
			flex-direction: row;

			& > div {
				flex-grow: 1;
				text-align: center;
				padding: 10px 0;
				background-color: $color;
				color: #fff;
				cursor: pointer;
				transition: 0.2s;
				font-size: 14px;
				font-weight: bold;

				&.active, &:hover {
					background-color: $activeColor;
				}
			}
		}

		.calendar-container {
			overflow: hidden;
			flex-grow: 1;
			position: relative;

			& > * {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	&.focused {
		border-color: $color;

		.calendar-popup {
			visibility: visible;
			opacity: 1;
		}
	}
}
