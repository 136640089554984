.main-footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-bottom: 120px
}

@media (max-width:992px) {
	.main-footer {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}
}

@media (max-width:767px) {
	.main-footer {
		padding-bottom: 65px
	}
}

.main-footer .block {
	width: 33%
}

@media (max-width:992px) {
	.main-footer .block {
		width: 50%
	}
	.main-footer .block:nth-child(1) {
		width: 100%;
		margin-bottom: 45px
	}
}

@media (max-width:767px) {
	.main-footer .block:nth-child(1) {
		margin-bottom: 35px
	}
	.main-footer .block:nth-child(3) {
		padding-left: 10px
	}
	.main-footer .block:nth-child(3) .title {
		margin-bottom: 12px
	}
}

.main-footer .block .title {
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #19191b;
	margin-bottom: 20px
}

@media (max-width:767px) {
	.main-footer .block .title {
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 24px
	}
}

.main-footer .block .icon {
	font-size: 18px;
	line-height: 155%;
	color: #696871;
	display: block;
	padding-left: 60px;
	margin-bottom: 30px;
	position: relative
}

@media (max-width:767px) {
	.main-footer .block .icon {
		font-size: 12px;
		margin-bottom: 13px
	}
}

.main-footer .block .icon:before {
	content: "";
	display: block;
	width: 32px;
	height: 32px;
	background: url('/assets/icon/icon__phone.svg') no-repeat;
	background-size: contain;
	position: absolute;
	top: 6px;
	left: 0;
	background-position: center center
}

@media (max-width:767px) {
	.main-footer .block .icon:before {
		width: 25px;
		height: 25px;
		left: 10px
	}
}

.main-footer .block .icon.mail:before {
	background-image: url('/assets/icon/icon__mail.svg')
}

.main-footer .block .menu {
	font-size: 18px;
	line-height: 155%;
	color: #696871;
	display: block;
	margin-bottom: 25px
}

@media (max-width:767px) {
	.main-footer .block .menu {
		font-size: 12px;
		margin-bottom: 20px
	}
}

.main-footer .block .social-block {
	margin-bottom: 47px
}

@media (max-width:767px) {
	.main-footer .block .social-block {
		margin-bottom: 24px
	}
}

.main-footer .block .social-block .social {
	width: 32px;
	height: 32px;
	background: url('/assets/icon/social__fb.svg') no-repeat;
	display: inline-block;
	vertical-align: top;
	margin-right: 30px;
	background-size: contain
}

@media (max-width:767px) {
	.main-footer .block .social-block .social {
		width: 25px;
		height: 25px;
		margin-right: 13px
	}
}

.main-footer .block .social-block .social.ig {
	background-image: url('/assets/icon/social__ig.svg')
}

.main-footer .block .copyright {
	font-size: 18px;
	line-height: 28px;
	color: #696871
}

@media (max-width:767px) {
	.main-footer .block .copyright {
		font-size: 12px
	}
}

.main-footer .block:nth-child(2) {
	padding-right: 65px
}

@media (max-width:767px) {
	.main-footer .block:nth-child(2) {
		padding-right: 0
	}
}
