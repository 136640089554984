@import "constants/style";

.balance-block {
	margin-top: 25px;

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 20px 0;
	}

	.active-subscription {
		margin: 20px 0;

		&:not(:last-of-type) {
			border-bottom: 1px solid #302b63;
		}
	}

	.item {
		margin-bottom: 20px;

		.item-label {
			font-size: 16px;
			line-height: 150%;
			color: #696871;

			@media (max-width: $mobileMaxWidth) {
				font-size: 12px;
			}
		}

		.item-info {
			font-weight: 500;
			font-size: 18px;
			line-height: 150%;
			color: #352e6a;

			@media (max-width: $mobileMaxWidth) {
				font-size: 14px;
			}
		}
	}

	.btn-block {
		margin-top: 30px;

		.btn {
			display: block;
			padding-left: 0;
			padding-right: 0;
			height: 45px;
			max-width: 84%;
			margin: 0 auto;
		}

		@media (max-width: $mobileMaxWidth) {
			.btn {
				max-width: 95%;
			}
		}
	}
}

.pay-form {
	display: block !important;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;

	&.active {
		visibility: visible;
		opacity: 1;
	}
}

.history-block {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 30px 1fr;
	grid-template-columns: 1fr 30px 1fr;
	margin-top: 30px;

	@media (max-width: $padMaxWidth) {
		display: block;
		margin-top: 17px;
	}

	.history-btn {
		-ms-grid-column: 1;
		grid-column: 1;
		-ms-grid-row: 1;
		grid-row: 1;

		#history-pay {
			display: block;
			font-weight: 600;
			font-size: 18px;
			line-height: 150%;
			color: #19191b;
			-webkit-transition: all .2s ease;
			transition: all .2s ease;
			cursor: pointer;

			@media (max-width: $mobileMaxWidth) {
				font-size: 12px;
			}

			.icon {
				width: 21px;
				height: 28px;
				background: url('/assets/img/icon__history.png') no-repeat;
				background-size: contain;
				display: inline-block;
				vertical-align: middle;
				margin-right: 30px;
				-webkit-transition: all .2s ease;
				transition: all .2s ease;
			}

			&.active {
				background: #504da6;
				color: #fff;

				.icon {
					background-image: url('/assets/img/icon__history-active.png');
				}
			}
		}
	}

	.history-info {
		-ms-grid-row: 1;
		grid-row: 1;
		-ms-grid-column: 3;
		grid-column: 3;
		display: none;


		@media (max-width: $padMaxWidth) {
			margin-top: 17px;
		}

		&.visible {
			display: block;
		}

		.history-list .item {
			font-size: 12px;
			line-height: 15px;
			color: #35384d;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:not(:last-of-type) {
				margin-bottom: 10px;
			}

			.date {
				font-weight: 500
			}
		}
	}
}
