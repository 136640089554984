@import "constants/style";

$arrowColor: str-slice('' + $controlText, 2);

.app-select {
	width: 100%;
	border: 1px solid $border;
	border-radius: 10px;
	height: 48px;
	padding: 12px;
	padding-right: 30px;
	font-family: Montserrat,sans-serif;
	font-size: 16px;
	text-overflow: ellipsis;
	color: $controlText;
	-webkit-transition: all .2s ease;
	transition: all .2s ease;

	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: $back;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='6' fill='%23" + $arrowColor + "'><polygon points='0,0 12,0 6,6'/></svg>");
	background-position: calc(100% - 10px) center;
	background-repeat: no-repeat;

	&:focus {
		border-color: $color;
	}

	&:disabled {
		border-color: $disabledBorder;
		color: $disabledControlText;
	}

	@media (max-width: $mobileMaxWidth) {
		height: 40px;
		font-size: 12px;
	}
}
