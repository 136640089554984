.calendar .years-scroll {
	flex-grow: 1;
	overflow-y: scroll;

	& > .years {
		position: relative;
		overflow: hidden;
		min-height: 100%;
		display: grid;
		align-content: start;
		grid-template-columns: repeat(3, 1fr);

		& > div {
			display: flex;
			align-items: stretch;
			padding: 2px 0;
			height: 100%;
			position: relative;

			& > div {
				flex-grow: 1;
				display: flex;
				align-items: stretch;

				& > div {
					flex-grow: 1;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.current {
				position: absolute;
				box-sizing: border-box;
				left: 50%;
				top: 50%;
				border-radius: 50%;
				border: 2px solid;
			}
		}
	}
}
