@import "constants/style";

$back: rgba(231, 230, 244, 0.7);
$contentBack: #ffffff;

.app-popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $back;
	backdrop-filter: blur(7px);
	z-index: 99999;

	& > div {
		width: 100%;
		height: 100%;
		max-width: $displayMaxWidth;
		display: flex;
		justify-content: center;
		align-items: center;

		& > div {
			max-width: calc(100vw - 100px);
			max-height: calc(100vh - 100px);
			background-color: $contentBack;
			box-shadow: 0 0 5px rgba(0, 0, 0, .0986943);
			border-radius: 10px;
			padding: 20px;
			overflow: auto;

			@media (max-width: $mobileMaxWidth) {
				max-width: calc(100vw - 20px);
				max-height: calc(100vh - 20px);
			}
		}
	}
}
