@import "constants/style";

* {
	box-sizing: border-box;
	outline: 0!important;
}

.popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	height: 100vh;
}

.popup.fixed {
	position: fixed
}

.popup.big .popup__block .left-block {
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	padding-top: 60px
}

@media (max-width:767px) {
	.popup.big .popup__block .left-block {
		padding: 10px
	}
}

.popup.big .popup__block .right-block {
	width: 750px;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1
}

@media (max-width:767px) {
	.popup.big .popup__block .right-block {
		width: 100%
	}
}

.popup.big.overblock .popup__block {
	max-width: 925px;
	margin-right: auto;
	margin-left: auto
}

@media (max-width:992px) {
	.popup.big.overblock .popup__block {
		max-width: 90%
	}
}

.popup.overblock .popup__wrap {
	display: block;
	padding: 40px 0;
	overflow: auto
}

@media (max-width:767px) {
	.popup {
		height: auto
	}
}

.popup .overlay {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 21
}

.popup__wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	pointer-events: none
}

.popup__block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	pointer-events: auto;
	z-index: 22
}

@media (max-width:767px) {
	.popup__block {
		display: block;
		margin-right: auto;
		margin-left: auto;
		max-width: 90%
	}
}

.popup__block .one-block {
	min-height: 350px;
	width: 100%;
	max-width: 500px;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, .0986943);
	border-radius: 10px;
	text-align: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 50px
}

@media (max-width:767px) {
	.popup__block .one-block {
		padding: 0 20px
	}
}

.popup__block .one-block .title {
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
	letter-spacing: -.4px;
	color: #19191b;
	margin-bottom: 13px
}

@media (max-width:767px) {
	.popup__block .one-block .title {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 10px
	}
}

.popup__block .one-block .text {
	font-size: 18px;
	line-height: 150%;
	color: #696871;
	margin-bottom: 35px
}

@media (max-width:767px) {
	.popup__block .one-block .text {
		font-size: 14px;
		margin-bottom: 15px
	}
}

@media (max-width:767px) {
	.popup__block button {
		width: 100%
	}
}

.popup__block .left-block {
	background: #302b63;
	box-shadow: 0 0 5px rgba(0, 0, 0, .0986943);
	border-radius: 10px;
	width: 185px;
	position: relative;
	z-index: 1;
	margin-right: -10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 33px 0 23px
}

@media (max-width:767px) {
	.popup__block .left-block {
		width: 100%;
		margin-right: 0;
		display: block;
		padding: 10px
	}
}

@media (max-width:767px) {
	.popup__block .left-block__inner {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center
	}
}

.popup__block .left-block .logo {
	margin-bottom: 15px
}

@media (max-width:767px) {
	.popup__block .left-block .logo {
		margin-bottom: 0
	}
}

.popup__block .left-block .logo .img {
	max-width: 100%
}

@media (max-width:767px) {
	.popup__block .left-block .logo .img {
		max-width: 80px
	}
}

.popup__block .left-block .block {
	background: rgba(255, 255, 255, .3);
	border-radius: 5px;
	padding: 16px 0 24px 0;
	text-align: center
}

@media (max-width:767px) {
	.popup__block .left-block .block {
		padding: 20px
	}
}

.popup__block .left-block .block .ellipse {
	display: inline-block;
	width: 60px;
	height: 7px;
	background: rgba(54, 46, 102, .31);
	border-radius: 50%;
	margin-bottom: 25px
}

@media (max-width:767px) {
	.popup__block .left-block .block .ellipse {
		display: none
	}
}

.popup__block .left-block .block .title {
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #fff;
	font-family: Hero, sans-serif;
	margin-bottom: 8px
}

.popup__block .left-block .block .text {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #fff
}

.popup__block .right-block {
	width: 380px;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, .0986943);
	border-radius: 10px;
	position: relative;
	z-index: 2;
	padding: 25px 20px 40px 30px
}

@media (max-width:767px) {
	.popup__block .right-block {
		padding: 10px;
		width: auto
	}
}

.popup__block .right-block .tabs {
	display: flex;
	justify-content: space-between;
	margin-bottom: 18px;

	@media (max-width: $mobileMaxWidth) {
		margin-bottom: 10px;
	}

	.tab {
		&:not(:first-child) {
			margin-left: 10px;
		}

		@media (max-width: $mobileMaxWidth) {
			padding: 0;
		}
	}
}

.popup__block .right-block .tabs-content .desc {
	font-size: 18px;
	line-height: 22px;
	color: #696871;
	margin-bottom: 30px
}

@media (max-width:767px) {
	.popup__block .right-block .tabs-content .desc {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 15px
	}
}

.popup__block .input-block {
	margin-bottom: 20px
}

.popup__block .input-block > div {
	display: block;
}

.popup__block .link {
	font-size: 16px;
	line-height: 28px;
	text-decoration: underline;
	color: #302b63;
	cursor: pointer;
}

.popup__block .link.cancel {
	line-height: 40px;
}

@media (min-width:767px) {
	.popup__block .link.cancel {
		margin-left: 20px;
	}
}

@media (max-width:767px) {
	.popup__block .link {
		font-size: 14px
	}
}
