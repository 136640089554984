@import "constants/style";

.members-loading {
	display: flex;
	padding: 50px 0;
	justify-content: center;
}

.members-subscriptions-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	select {
		height: 40px;
		padding-top: 5px;
		padding-bottom: 5px;
		margin-left: 10px;
	}
}

.members-list-title {
	position: relative;
	z-index: 2;

	.members-add-btn {
		padding: 0;
		width: 50px;
		height: 40px;
	}

	.members-add-variants {
		position: absolute;
		top: calc(100% + 5px);
		right: -5px;
		padding: 5px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		background: #fff;
		box-shadow: 0 0 5px rgb(0 0 0 / 30%);
		visibility: hidden;
		opacity: 0;
		transition: 0.2s;


		&.active {
			visibility: visible;
			opacity: 1;
		}

		button {
			margin: 5px;
			padding: 0 20px;
		}

		input {
			display: none;
		}

		.members-add-file-container {
			display: flex;
			flex-direction: row;
		}
	}
}

.member-form {
	display: block !important;
	visibility: hidden;
	opacity: 0;
	transition: 0.3s;

	&.active {
		visibility: visible;
		opacity: 1;
	}

	.member-del-btn {
		border: none;
		padding: 5px;
		height: auto;
	}

	.member-form-input {
		margin-bottom: 15px;
	}

	.member-form-info {
		margin-bottom: 20px;
		opacity: 0.3;
	}
}

.search-member {
	margin-bottom: 20px;
}

.members-list {
	margin-right: -30px;
	margin-left: -30px;
	margin-bottom: -30px;
	height: 575px;
	overflow: auto;

	@media (max-width: $displayMaxWidth) {
		margin-right: -15px;
		margin-left: -15px;
		margin-bottom: -15px;
	}

	@media (max-width: $mobileMaxWidth) {
		height: 405px;
	}

	div {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		font-size: 16px;
		line-height: 20px;
		color: #696871;
		padding: 22px 30px;
		cursor: default;

		@media (max-width: $displayMaxWidth) {
			padding-right: 15px;
			padding-left: 15px;
		}

		@media (max-width: $mobileMaxWidth) {
			font-size: 14px;
			line-height: 17px;
			padding: 14px 15px;
		}

		&.active {
			transition: 0.2s;
			cursor: pointer;

			&:not(.selected):hover {
				background: #f6f6fc;
			}
		}

		&.selected {
			background: #ededf6;
			color: #352e6a;
			font-weight: 500;
		}

		&.subscribed img {
			height: 16px;
		}
	}
}

.members-import-help {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > div {
		max-width: 100%;

		span {
			display: block;
			width: 400px;
			max-width: 100%;
			text-align: center;
		}

		img {
			width: 400px;
			max-width: 100%;
			margin: 20px 0;
		}
	}
}
