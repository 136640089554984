$displayMaxWidth: 1120px;
$padMaxWidth: 992px;
$mobileMaxWidth: 767px;

$back: #fff;

$color: #302b63;
$disabledColor: #a7a6d3;
$activeColor: #4f479f;

$border: #d9d9d9;
$disabledBorder: #e7e7e7;

$controlText: #696871;
$disabledControlText: #a8a7b0;
$placeholder: #d6d6d6;

$error: #ff0000;

