.chart-doughnut {
	position: relative;

	.chart-doughnut-tooltip {
		position: absolute;
		pointer-events: none;
		transform: translate(-50%, 0);
		background-color: white;
		color: #696871;
		font-family: "Montserrat", sans-serif;
		font-style: normal;
		font-size: 10px;
		padding: 12px;
		border-radius: 5px;
		white-space: nowrap;
		z-index: 9999;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
		transition: 0.2s;

		.chart-doughnut-tooltip-label {
			font-weight: bold;
			margin-bottom: 5px;
		}
		.chart-doughnut-tooltip-value {
			display: flex;
			flex-direction: row;
			align-items: center;

			& > div {
				width: 10px;
				height: 10px;
				margin-right: 5px;
			}
		}
	}
}
