@import "constants/style";

.stat-block {
	.block-content {
		position: relative;

		.loading {
			position: absolute;
			left: 10px;
			top: 10px;
			right: 10px;
			bottom: 10px;
			display: flex;
			justify-content: center;
			align-items: center;
			backdrop-filter: blur(5px);
			background-color: rgba(255, 255, 255, 0.5);
			visibility: hidden;
			opacity: 0;
			transition: 0.2s;
			z-index: 9999;

			&.visible {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	.chart-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.options  {
			@media (min-width: $mobileMaxWidth) {
				margin-left: 10px;
			}

			& > * {
				height: 36px;
				min-height: 36px;
				border-radius: 5px;
				margin: 3px;

				@media (max-width: $mobileMaxWidth) {
					height: 30px;
					min-height: 30px;
				}
			}

			& > button {
				padding: 0 20px !important;
			}

			& > select {
				width: auto;
				padding: 0 30px 0 10px !important;
			}
		}

		@media (max-width: $mobileMaxWidth) {
			flex-direction: column;
		}
	}

	.dynamics-options  {
		& > * {
			height: 36px;
			min-height: 36px;
			border-radius: 5px;
			padding: 0 20px !important;
			margin: 3px;
		}
		& > .select {
			width: auto;
			padding: 0 30px 0 10px !important;
		}
	}

	.stat-list .item__inner {
		display: flex;
		align-items: flex-start;

		.img-block {
			margin-right: 15px;
		}
	}

	.info .list {
		padding: 0;
		margin: 0;

		&.row li {
			display: inline-block;

			&:not(:first-child) {
				margin-left: 30px;
			}
		}

		li {
			list-style-type: none;
			display: block;
			font-size: 10px;
			line-height: 20px;
			color: #696871;
			position: relative;
			padding-left: 15px;

			& > div {
				position: absolute;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				top: 50%;
				margin-top: -2.5px;
				left: 0
			}
		}
	}
}

@media print {
	.stat-block.two-column {
		display: grid;
		grid-template-columns: 1fr 30px 1fr;

		.block-content {
			margin-top: 0 !important;
		}
	}

	.stat-block .stat-list {
		display: flex;

		.item {
			width: 33%;
			margin-bottom: 0;
		}
	}

	.chart-header {
		flex-direction: row !important;

		.name br {
			display: none;
		}
		.options {
			display: none;
		}
	}
}

.statistics-extra {
	& > * {
		width: 100%;
	}

	& > button {
		margin: 3px 0;
	}
}

.statistics-pdf {
	position: fixed !important;
	left: -900px;
	top: 0;
	width: 900px;
	z-index: 99999;

	.print {
		display: block;
	}

	.sort-block {
		display: grid !important;

		.company-name {
			margin-bottom: 0 !important;
			font-size: 26px !important;
			line-height: 31px !important;

			span {
				font-size: 17px !important;
				line-height: 21px !important;
				margin-left: 20px !important;
			}
		}
	}

	.app-calendar-container {
		height: 48px !important;
		font-size: 16px !important;
	}


	.stat-block {
		display: grid !important;

		&.two-column {
			grid-template-columns: 1fr 30px 1fr;

			.block-content {
				margin-top: 0 !important;
			}
		}

		.stat-list {
			display: flex;

			.item {
				width: 33%;
				margin-bottom: 0;

				.title {
					font-size: 14px !important;
				}
			}
		}
	}

	.block-content {
		.name {
			font-size: 18px !important;
			line-height: 150% !important;
			margin-bottom: 15px !important;
		}

		.count-block .count {
			font-size: 31px !important;
			line-height: 38px !important;
		}
	}

	.chart-header {
		flex-direction: row !important;

		.name br {
			display: none;
		}
		.options {
			display: none;
		}
	}
}
